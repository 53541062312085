import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faCogs,
  faClock,
  faLeaf,
} from "@fortawesome/free-solid-svg-icons";

const WhyChooseUs: React.FC = () => {
  return (
    <section className="container mx-auto px-4 py-10">
      <h2 className="text-3xl md:text-4xl font-bold text-center mb-4 text-blue-600">
        Why trust your building’s care to anyone else?
      </h2>
      <p className="text-lg md:text-xl text-center text-gray-700 mb-12">
        At NBS, we take pride in offering services that exceed expectations.
        Here’s why businesses choose us:
      </p>
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
        {/* Expert Team Card */}
        <div className="flex flex-col items-center text-center p-6 border rounded-lg shadow-md hover:shadow-lg transition">
          <div className="text-blue-600 mb-4">
            <FontAwesomeIcon icon={faUsers} size="3x" />
          </div>
          <h3 className="text-xl font-semibold mb-2">Expert Team</h3>
          <div className="mx-auto" style={{ maxWidth: "500px" }}>
            <p
              className="text-gray-700"
              style={{
                textAlign: "justify",
                textAlignLast: "left",
                hyphens: "auto",
                wordSpacing: "normal",
                letterSpacing: "normal",
              }}
            >
              Our experienced staff members are trained and equipped to handle
              all your building’s needs.
            </p>
          </div>
        </div>
        {/* Customized Solutions Card */}
        <div className="flex flex-col items-center text-center p-6 border rounded-lg shadow-md hover:shadow-lg transition">
          <div className="text-blue-600 mb-4">
            <FontAwesomeIcon icon={faCogs} size="3x" />
          </div>
          <h3 className="text-xl font-semibold mb-2">Customized Solutions</h3>
          <div className="mx-auto" style={{ maxWidth: "500px" }}>
            <p
              className="text-gray-700"
              style={{
                textAlign: "justify",
                textAlignLast: "left",
                hyphens: "auto",
                wordSpacing: "normal",
                letterSpacing: "normal",
              }}
            >
              Every facility is unique. We offer tailored cleaning, maintenance,
              and construction solutions that align with your business needs.
            </p>
          </div>
        </div>
        {/* 24/7 Availability Card */}
        <div className="flex flex-col items-center text-center p-6 border rounded-lg shadow-md hover:shadow-lg transition">
          <div className="text-blue-600 mb-4">
            <FontAwesomeIcon icon={faClock} size="3x" />
          </div>
          <h3 className="text-xl font-semibold mb-2">24/7 Availability</h3>
          <div className="mx-auto" style={{ maxWidth: "500px" }}>
            <p
              className="text-gray-700"
              style={{
                textAlign: "justify",
                textAlignLast: "left",
                hyphens: "auto",
                wordSpacing: "normal",
                letterSpacing: "normal",
              }}
            >
              Emergencies don’t keep office hours, and neither do we. Our team
              is available around the clock to ensure your building stays in top
              condition.
            </p>
          </div>
        </div>
        {/* Eco-Friendly Practices Card */}
        <div className="flex flex-col items-center text-center p-6 border rounded-lg shadow-md hover:shadow-lg transition">
          <div className="text-blue-600 mb-4">
            <FontAwesomeIcon icon={faLeaf} size="3x" />
          </div>
          <h3 className="text-xl font-semibold mb-2">Eco-Friendly Practices</h3>
          <div className="mx-auto" style={{ maxWidth: "500px" }}>
            <p
              className="text-gray-700"
              style={{
                textAlign: "justify",
                textAlignLast: "left",
                hyphens: "auto",
                wordSpacing: "normal",
                letterSpacing: "normal",
              }}
            >
              We prioritize sustainability with eco-friendly cleaning products
              and energy-efficient maintenance practices.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
