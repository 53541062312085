import React from "react";
import BackgroundCarousel from "../components/Main/BackgroundCarousel";
import Slogan from "../components/Main/Slogan";
import WhyChooseUs from "../components/Main/WhyChooseUs";
import Services from "../components/Main/Services/Services";
import Clients from "../components/Main/Clients";
import ContactForm from "../components/Main/Buttons/ContactForm";
import ColorTransitionLine from "../components/Main/ColorTransitionLine";
import GetInTouchLine from "../components/Main/GetInTouchLine";
import CarouselCaption from "../components/Main/CarouselCaption";

const Home: React.FC = () => {
  return (
    <>
      <section className="mt-32">
        <BackgroundCarousel />
      </section>
      <section>
        <CarouselCaption />
      </section>
      <Slogan />
      <WhyChooseUs />
      <Services selectedService={null} />
      <Clients />
      <section className="-mb-10">
        <GetInTouchLine />
      </section>
      <ContactForm />
    </>
  );
};

export default Home;
