import React, { useState, useEffect, useCallback } from "react";
import "../../../styles/styles.css";
import janitorial from "../../../assets/images/servicesimgs/Janitorial.png";
import maintenance from "../../../assets/images/servicesimgs/Maintenence.png";
import exteriorservices from "../../../assets/images/servicesimgs/ExteriorServices.png";
import constructionservices from "../../../assets/images/servicesimgs/ConstructionServices.png";
import janitorialImg from "../../../assets/images/servicesimgs/janitorial-services-img.webp";
import maintenanceImg from "../../../assets/images/servicesimgs/maintenance-services-img.jpg";
import exteriorImg from "../../../assets/images/servicesimgs/exterior-services-img.jpeg";
import constructionImg from "../../../assets/images/servicesimgs/construction-services-img.webp";
import janitorial2 from "../../../assets/images/servicesimgs/Janitorial3.png";
import maintenance2 from "../../../assets/images/servicesimgs/Maintenence3.png";
import exteriorservices2 from "../../../assets/images/servicesimgs/ExteriorServices3.png";
import constructionservices2 from "../../../assets/images/servicesimgs/ConstructionServices2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const services = [
  {
    name: "Construction Services",
    defaultImage: constructionservices2,
    hoverImage: constructionImg,
    modalContent: (
      <div className="modal-content space-y-4">
        <ul className="list-none space-y-2 text-center">
          <li className="bg-gray-300 p-2 rounded shadow-sm">
            Commercial and Industrial Cleaning
          </li>
          <li className="bg-gray-300 p-2 rounded shadow-sm">
            Construction Clean
          </li>
          <li className="bg-gray-300 p-2 rounded shadow-sm">
            Floor Care and Restoration
          </li>
          <li className="bg-gray-300 p-2 rounded shadow-sm">
            Porter and Matron Services
          </li>
        </ul>
      </div>
    ),
  },
  {
    name: "Exterior Services",
    defaultImage: exteriorservices2,
    hoverImage: exteriorImg,
    modalContent: (
      <div className="modal-content space-y-4">
        <ul className="list-none space-y-2 text-center">
          <li className="bg-gray-300 p-2 rounded shadow-sm">
            Landscaping and Tree Trimming
          </li>
          <li className="bg-gray-300 p-2 rounded shadow-sm">
            Mulching and Planting
          </li>
          <li className="bg-gray-300 p-2 rounded shadow-sm">
            Paved Surfaces, Fences, and Trash
          </li>
          <li className="bg-gray-300 p-2 rounded shadow-sm">
            Pressure Washing
          </li>
        </ul>
      </div>
    ),
  },
  {
    name: "Janitorial",
    defaultImage: janitorial2,
    hoverImage: janitorialImg,
    modalContent: (
      <div className="modal-content space-y-4">
        <ul className="list-none space-y-2 text-center">
          <li className="bg-gray-300 p-2 rounded shadow-sm">
            Commercial and Industrial Cleaning
          </li>
          <li className="bg-gray-300 p-2 rounded shadow-sm">
            Construction Clean
          </li>
          <li className="bg-gray-300 p-2 rounded shadow-sm">
            Floor Care and Restoration
          </li>
          <li className="bg-gray-300 p-2 rounded shadow-sm">
            Porter and Matron Services
          </li>
        </ul>
      </div>
    ),
  },
  {
    name: "Maintenance",
    defaultImage: maintenance2,
    hoverImage: maintenanceImg,
    modalContent: (
      <div className="modal-content space-y-4">
        <ul className="list-none space-y-2 text-center">
          <li className="bg-gray-300 p-2 rounded shadow-sm">Carpentry</li>
          <li className="bg-gray-300 p-2 rounded shadow-sm">Electrical</li>
          <li className="bg-gray-300 p-2 rounded shadow-sm">
            Facilities Maintenance
          </li>
          <li className="bg-gray-300 p-2 rounded shadow-sm">HVAC</li>
          <li className="bg-gray-300 p-2 rounded shadow-sm">Locksmith</li>
          <li className="bg-gray-300 p-2 rounded shadow-sm">Masonry</li>
          <li className="bg-gray-300 p-2 rounded shadow-sm">Painting</li>
          <li className="bg-gray-300 p-2 rounded shadow-sm">Plumbing</li>
        </ul>
      </div>
    ),
  },
];

interface ServicesProps {
  selectedService: string | null;
}

const Services: React.FC<ServicesProps> = ({ selectedService }) => {
  const [currentService, setCurrentService] = useState<
    null | (typeof services)[0]
  >(null);
  const [hoveredService, setHoveredService] = useState<string | null>(null);
  const [activeCollapsible, setActiveCollapsible] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (selectedService) {
      const service = services.find(
        (service) => service.name === selectedService
      );
      if (service) {
        setCurrentService(service);
      }
    } else {
      setCurrentService(null);
    }
  }, [selectedService]);

  useEffect(() => {
    if (currentService) {
      document.body.style.overflow = "hidden";
      setHoveredService(null);
    } else {
      document.body.style.overflow = "auto";
    }
  }, [currentService]);

  const closeModal = () => {
    setCurrentService(null);
    setActiveCollapsible(null);
  };

  const handleToggleCollapsible = useCallback((title: string) => {
    setActiveCollapsible((prev) => (prev === title ? null : title));
  }, []);

  const handleServiceClick = (service: (typeof services)[0]) => {
    setCurrentService(service);
    setHoveredService(null);
  };

  // Sort services alphabetically by name
  const sortedServices = [...services].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <section id="services" className="bg-gray-100 py-20 relative">
      <div className="container mx-auto px-4 md:px-6">
        {/* Impactful Section Header */}
        <div className="text-center mb-12 relative">
          <h2 className="text-4xl md:text-5xl font-bold text-blue-600 mb-4">
            Discover Our Services
          </h2>
          <p className="text-lg md:text-xl text-gray-700 mb-6">
            Comprehensive Services for Your Business Needs.
          </p>
          <div className="mt-6">
            <FontAwesomeIcon
              icon={faChevronDown}
              className="text-blue-600 text-2xl animate-bounce"
            />
          </div>
        </div>
        {/* Service Cards using Grid */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-6 lg:gap-8">
          {sortedServices.map((service, index) => (
            <div
              key={index}
              className="relative group service-card cursor-pointer"
              onClick={() => handleServiceClick(service)}
              onMouseEnter={() => setHoveredService(service.name)}
              onMouseLeave={() => setHoveredService(null)}
            >
              <div
                className="flex items-center justify-center p-4 h-40 md:h-48 lg:h-56 bg-white rounded-lg shadow-lg transition-transform duration-300 transform hover:scale-110"
                style={{
                  backgroundImage: `url(${
                    hoveredService === service.name
                      ? service.hoverImage
                      : service.defaultImage
                  })`,
                  backgroundSize:
                    hoveredService === service.name ? "cover" : "50% auto",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              ></div>
              <div
                className={`absolute inset-0 bg-cyan-900 bg-opacity-75 flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg ${
                  currentService ? "hidden" : ""
                }`}
              >
                <h3 className="text-white text-base md:text-2xl lg:text-3xl font-bold text-center service-title">
                  {service.name}
                </h3>
                <p className="mt-2 text-md text-blue-300">Learn More</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Modal */}
      {currentService && (
        <div
          className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div
            className="relative w-10/12 sm:w-8/12 md:w-2/3 lg:w-1/3 max-h-screen p-4 sm:p-6"
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className="modal-bg border-2 border-cyan-900 mb-6"
              style={{
                backgroundImage: `url(${currentService.hoverImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "0",
              }}
            ></div>
            <div className="relative bg-white p-4 sm:p-6 rounded-lg shadow-2xl bg-opacity-80">
              <button
                className="absolute top-0 right-0 mt-4 mr-3 text-xl text-gray-500 hover:text-red-500 transform -translate-y-1/2"
                onClick={closeModal}
              >
                &times;
              </button>
              <h3 className="text-2xl font-bold mb-4 text-center text-cyan-900">
                {currentService.name}
              </h3>
              <div className="text-xs sm:text-sm service-text">
                {currentService.name === "Maintenance" && (
                  <ul className="list-none space-y-2 text-center">
                    <li className="bg-gray-200 p-2 rounded shadow-sm">
                      Carpentry
                    </li>
                    <li className="bg-gray-200 p-2 rounded shadow-sm">
                      Electrical
                    </li>
                    <li className="bg-gray-200 p-2 rounded shadow-sm">
                      Facilities Maintenance
                    </li>
                    <li className="bg-gray-200 p-2 rounded shadow-sm">HVAC</li>
                    <li className="bg-gray-200 p-2 rounded shadow-sm">
                      Flooring
                    </li>
                    <li className="bg-gray-200 p-2 rounded shadow-sm">
                      Locksmith
                    </li>
                    <li className="bg-gray-200 p-2 rounded shadow-sm">
                      Painting
                    </li>
                    <li className="bg-gray-200 p-2 rounded shadow-sm">
                      Plumbing
                    </li>
                  </ul>
                )}
                {currentService.name === "Exterior Services" && (
                  <ul className="list-none space-y-2 text-center">
                    <li className="bg-gray-200 p-2 rounded shadow-sm">
                      Landscaping and Tree Trimming
                    </li>
                    <li className="bg-gray-200 p-2 rounded shadow-sm">
                      Mulching and Planting
                    </li>
                    <li className="bg-gray-200 p-2 rounded shadow-sm">
                      Paved Surfaces, Fences, and Trash
                    </li>
                    <li className="bg-gray-200 p-2 rounded shadow-sm">
                      Pressure Washing
                    </li>
                  </ul>
                )}
                {currentService.name === "Janitorial" && (
                  <ul className="list-none space-y-2 text-center">
                    <li className="bg-gray-200 p-2 rounded shadow-sm">
                      Commercial and Industrial Cleaning
                    </li>
                    <li className="bg-gray-200 p-2 rounded shadow-sm">
                      Construction Clean
                    </li>
                    <li className="bg-gray-200 p-2 rounded shadow-sm">
                      Floor Care and Restoration
                    </li>
                    <li className="bg-gray-200 p-2 rounded shadow-sm">
                      Porter and Matron Services
                    </li>
                  </ul>
                )}
                {currentService.name === "Construction Services" && (
                  <ul className="list-none space-y-2 text-center">
                    <li className="bg-gray-200 p-2 rounded shadow-sm">
                      Carpentry
                    </li>
                    <li className="bg-gray-200 p-2 rounded shadow-sm">
                      Electrical
                    </li>
                    <li className="bg-gray-200 p-2 rounded shadow-sm">
                      Flooring
                    </li>
                    <li className="bg-gray-200 p-2 rounded shadow-sm">
                      Masonry
                    </li>
                    <li className="bg-gray-200 p-2 rounded shadow-sm">Paint</li>
                    <li className="bg-gray-200 p-2 rounded shadow-sm">
                      Plumbing
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Services;
