import React, { useEffect } from "react";
import AboutUsContent from "../components/Main/AboutUSContent";
import MissionAndValues from "../components/Main/MissionsAndValues";
import Owners from "../components/Main/Owners";
import ColorTransitionLine from "../components/Main/ColorTransitionLine";
import GetInTouchLine from "../components/Main/GetInTouchLine";
import ContactForm from "../components/Main/Buttons/ContactForm";

const AboutUs: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Place AboutUsContent directly below header */}
      <section className="mt-48">
        <AboutUsContent />
      </section>
      {/* Slightly pull MissionAndValues closer */}
      <section className="-mt-10">
        <MissionAndValues />
      </section>
      <section className="container mx-auto px-4 md:px-6 mt-2">
        <Owners />
      </section>
      <ContactForm />
      <section className="-mb-10">
        <GetInTouchLine />
      </section>
    </>
  );
};

export default AboutUs;
