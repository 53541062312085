// components/Main/Owners.tsx
import React from "react";
import owner1 from "../../assets/images/Owner1.jpg";
import owner3 from "../../assets/images/Owner1.1.jpg";
import owner2 from "../../assets/images/Owner2.jpg";
import owner4 from "../../assets/images/Owner2.2.jpg";

const Owners: React.FC = () => {
  return (
    <section className="mb-20">
      <h2 className="text-xl md:text-2xl text-center text-blue-600 mb-6">
        Meet Eduardo Artigas and Michael Martin, the Visionaries Behind NBS
      </h2>
      <section className="grid md:grid-cols-2 gap-10 mt-16">
        {/* Eduardo Artigas */}
        <div className="grid grid-rows-[minmax(300px,auto)_auto]">
          <div className="flex flex-col items-center">
            <img
              src={owner4}
              alt="Eduardo Artigas"
              className="w-48 h-48 object-cover rounded-full mb-4 shadow-md"
            />
            <h3 className="text-2xl font-semibold mt-4 mb-2">
              Eduardo Artigas
            </h3>
            <p className="text-gray-700 mt-4 mb-4 text-justify">
              Eduardo Artigas is the founder and driving force behind Nationwide
              Building Solutions. Originally from Cuba, his career began with a
              hands-on experience in every facet of the service sector. With
              over 30 years of experience, he has built a reputation for
              integrity, reliability, and exceptional customer service.
            </p>
          </div>
          <blockquote className="mt-4 italic text-gray-600 border-l-4 border-cyan-900 pl-4 text-justify">
            “When Eduardo is not leading the company, he enjoys singing, dancing
            and performing at any venue that will allow him. He is a dedicated
            husband to his wife Vivian and father to his three children. He
            enjoys jet skiing, biking and dining out.”
          </blockquote>
        </div>
        {/* Michael Martin */}
        <div className="grid grid-rows-[minmax(300px,auto)_auto]">
          <div className="flex flex-col items-center">
            <img
              src={owner3}
              alt="Michael Martin"
              className="w-48 h-48 object-cover rounded-full mb-4 shadow-md"
            />
            <h3 className="text-2xl font-semibold mt-4 mb-2">Michael Martin</h3>
            <p className="text-gray-700 mt-4 mb-4 text-justify">
              Michael Martin, born in Jamaica, has lived all over the world and
              is the co-founder of Nationwide Building Solutions. With a strong
              foundation in business development and operational excellence, he
              has built and scaled multiple companies.
            </p>
          </div>
          <blockquote className="mt-4 italic text-gray-600 border-l-4 border-cyan-900 pl-4 text-justify">
            Michael enjoys working out, playing tennis/pickleball, and being
            outdoors. He likes to spend time with his wife Katherine, his 3
            children, and their 4 dogs.
          </blockquote>
        </div>
      </section>
    </section>
  );
};

export default Owners;
