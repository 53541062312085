import React from "react";
import logo from "../../assets/images/nw-logo-sv.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const Footer: React.FC = () => {
  return (
    <footer id="contact" className="bg-gray-200 py-4 md:py-6">
      {/* Main Contact Info */}
      <div className="container mx-auto px-4 md:px-8 flex flex-col md:flex-row justify-between items-center mt-4">
        <div className="text-center md:text-left mx-2 md:mx-4 my-2 md:my-0">
          <div className="mt-2">
            <p className="text-xs md:text-sm lg:text-base my-1">
              <FontAwesomeIcon icon={faPhone} className="mr-2" />
              Ed: 813-477-1887 / Michael: 813-601-2953
            </p>
            <p className="text-xs md:text-sm lg:text-base my-1">
              <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
              <a href="mailto:Katherine.nbs.team@gmail.com">
                Katherine.nbs.team@gmail.com
              </a>
            </p>
            <p className="text-xs md:text-sm lg:text-base my-1 flex items-center justify-center md:justify-start">
              <FontAwesomeIcon
                icon={faInstagram as IconProp}
                className="mr-2"
              />
              @nationwidebuildingsolutions
            </p>
            <p className="text-xs md:text-sm lg:text-base my-1">
              <FontAwesomeIcon icon={faLocationDot} className="mr-2" />
              3330 West Cypress Street, Tampa, FL 33607
            </p>
          </div>
        </div>
        <div className="text-center my-2 md:my-0">
          <img src={logo} alt="Logo" className="w-20 md:w-24 h-auto" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
