import React from "react";

const AboutUsContent: React.FC = () => {
  return (
    <section className="">
      <h1 className="text-4xl md:text-5xl font-bold text-center mb-6 text-blue-900">
        About Us
      </h1>
      {/* Full-width band with company description */}
      <div className="w-screen bg-gray-200 py-8 mt-16">
        <div className="container mx-auto px-4 md:px-6 mb-16 -mt-6">
          <p className="text-gray-700 text-justify mt-8 mb-4">
            Founded in 2020 by Eduardo Artigas and Michael Martin, partners with
            over 35 years in the janitorial industry, Nationwide Building
            Solutions is already a leading name in janitorial and facilities
            maintenance in Florida. Our diverse client base spans industries
            like healthcare, automotive, hospitality, and more. We strive for
            excellence, and should any issues arise, we are committed to prompt
            resolution, ensuring client satisfaction remains our top priority.
            <br />
            <br />
            At Nationwide Building Solutions, we understand that a clean and
            well-maintained facility is critical to your business operations. We
            offer reliable and professional commercial cleaning, building
            maintenance, and construction services nationwide. Our commitment to
            quality and customer satisfaction makes us the trusted partner for
            businesses of all sizes.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutUsContent;
