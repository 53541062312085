import React, { useState, useEffect } from "react";
import service1 from "../../assets/images/service1.jpeg";
import service2 from "../../assets/images/service2.jpg";
import service3 from "../../assets/images/service3.jpg";
import service4 from "../../assets/images/service4.jpg";
import service5 from "../../assets/images/service5.jpg";
import service6 from "../../assets/images/service6.jpg";
import service7 from "../../assets/images/service7.jpg";
import service8 from "../../assets/images/service8.jpg";
import service9 from "../../assets/images/service9.jpg";
import service10 from "../../assets/images/service10.jpg";
import service11 from "../../assets/images/service11.jpg";
import service12 from "../../assets/images/service12.jpg";

const BackgroundCarousel: React.FC = () => {
  // Array with carousel images
  const images = [
    service1,
    service9,
    //service2,
    service3,
    service8,
    service4,
    service6,
    service5,
    //service7,
    service10,
    service11,
    service12,
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // State for animating slogan colors (if needed later)
  const [colors, setColors] = useState({
    quality: "",
    reliability: "",
    integrity: "",
  });
  const [loop, setLoop] = useState(0);

  // Update carousel image every 5 seconds
  useEffect(() => {
    const carouselInterval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
    return () => clearInterval(carouselInterval);
  }, [images.length]);

  // Animate slogan colors (currently not used because overlay is commented)
  useEffect(() => {
    const timeouts = [
      setTimeout(
        () => setColors((prev) => ({ ...prev, quality: "#2f5194" })),
        1500
      ),
      setTimeout(
        () => setColors((prev) => ({ ...prev, reliability: "#e22328" })),
        2500
      ),
      setTimeout(
        () => setColors((prev) => ({ ...prev, integrity: "#ffff00" })),
        3500
      ),
      setTimeout(() => {
        setColors({ quality: "", reliability: "", integrity: "" });
        setLoop((prev) => prev + 1);
      }, 5500),
    ];
    return () => timeouts.forEach(clearTimeout);
  }, [loop]);

  // Hover effect for footer letters (if used elsewhere)
  const handleLetterHover = (event: React.MouseEvent<HTMLSpanElement>) => {
    const target = event.target as HTMLSpanElement;
    if (target.textContent) {
      const index = Array.prototype.indexOf.call(
        target.parentNode?.childNodes,
        target
      );
      target.style.color = index % 2 === 0 ? "#e22328" : "#2f5194";
      target.style.cursor = "default";
    }
  };

  const handleLetterLeave = (event: React.MouseEvent<HTMLSpanElement>) => {
    const target = event.target as HTMLSpanElement;
    target.style.color = "";
  };

  return (
    <div className="relative w-full h-[74vh] overflow-hidden mt-16">
      {/* Carousel image with reduced vertical height */}
      <img
        src={images[currentImageIndex]}
        alt="Background"
        className="absolute inset-0 object-cover w-full h-full z-0 transition-opacity duration-1000"
        style={{ opacity: 1 }}
      />

      {/*
      The overlay text block (slogan) is now commented out.
      <div className="absolute inset-x-0 top-1/2 transform -translate-y-1/2 text-white z-10 text-center w-full">
        <div className="bg-black bg-opacity-30 backdrop-blur-sm inline-block px-4 py-2 rounded -mt-48">
          <h1 className="text-xl md:text-5xl lg:text-6xl font-bold font-clarika-pro-geo-bd">
            <span style={{ color: colors.quality }}>Quality</span>
            <span>, </span>
            <span style={{ color: colors.reliability }}>Reliability</span>
            <span>, and </span>
            <span style={{ color: colors.integrity }}>Integrity</span>
          </h1>
        </div>
      </div>
      */}
    </div>
  );
};

export default BackgroundCarousel;
