import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAward,
  faUsers,
  faBullseye,
} from "@fortawesome/free-solid-svg-icons";

const MissionAndValues: React.FC = () => {
  return (
    <section id="mission-values" className="bg-white py-20">
      <div className="container mx-auto px-4 md:px-6">
        <h2 className="text-lg md:text-2xl text-justify text-black mb-4">
          Our <span className="text-blue-600 font-bold">MISSION</span> is
          simple: to provide exceptional building maintenance, cleaning, and
          construction services that help our clients maintain a safe, clean,
          and productive environment.
        </h2>
        <div className="grid gap-8 md:grid-cols-3 mt-16">
          {/* Commitment to Excellence Card */}
          <div className="p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300 border border-gray-200">
            <div className="flex justify-center mb-4">
              <FontAwesomeIcon
                icon={faAward}
                className="text-blue-600 text-4xl"
              />
            </div>
            <h3 className="text-xl font-semibold text-center mb-2">
              Commitment to Excellence
            </h3>
            <p className="text-gray-700 text-justify">
              We deliver exceptional janitorial and facilities maintenance
              services, ensuring superior cleanliness and safety.
            </p>
          </div>
          {/* Customer-Centric Approach Card */}
          <div className="p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300 border border-gray-200">
            <div className="flex justify-center mb-4">
              <FontAwesomeIcon
                icon={faUsers}
                className="text-blue-600 text-4xl"
              />
            </div>
            <h3 className="text-xl font-semibold text-center mb-2">
              Customer-Centric Approach
            </h3>
            <p className="text-gray-700 text-justify">
              We prioritize our clients, fostering long-term partnerships built
              on trust, reliability, and quality service.
            </p>
          </div>
          {/* Innovative Problem-Solving Card */}
          <div className="p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300 border border-gray-200">
            <div className="flex justify-center mb-4">
              <FontAwesomeIcon
                icon={faBullseye}
                className="text-blue-600 text-4xl"
              />
            </div>
            <h3 className="text-xl font-semibold text-center mb-2">
              Innovative Problem-Solving
            </h3>
            <div className="mx-auto" style={{ maxWidth: "500px" }}>
              <p
                className="text-gray-700"
                style={{
                  textAlign: "justify",
                  textAlignLast: "left",
                  hyphens: "auto",
                  wordSpacing: "normal",
                  letterSpacing: "normal",
                }}
              >
                We tackle challenges head-on, delivering innovative solutions
                for cleaner, safer, and more efficient environments.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissionAndValues;
