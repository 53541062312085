import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import nwLogo from "../../assets/images/nw-logo.png";
import "../../styles/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import ColorTransitionLine from "../Main/ColorTransitionLine";

interface HeaderProps {
  onServiceClick: (serviceName: string) => void;
}

const Header: React.FC<HeaderProps> = ({ onServiceClick }) => {
  const location = useLocation();
  const isMainPage = location.pathname === "/";
  const isAboutPage = location.pathname === "/about";

  const [scrolling, setScrolling] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 0);
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest(".menu-toggle-button")
      ) {
        setMenuOpen(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    targetId: string
  ) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      const offset = 120; // Increased offset so sections aren't hidden under the fixed header.
      const elementPosition = targetElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });

      closeMenu();
    }
  };

  let menuItems;
  if (isAboutPage) {
    // On the About Us page, display only the HOME link.
    menuItems = (
      <li className="py-3 md:py-0">
        <Link
          to="/"
          className="header-link"
          onClick={() => {
            closeMenu();
            window.scrollTo(0, 0);
          }}
        >
          HOME
        </Link>
      </li>
    );
  } else if (isMainPage) {
    // On the main page, display the remaining menu items (excluding ABOUT).
    menuItems = (
      <>
        <li className="py-3 md:py-0">
          <a
            href="#services"
            className="header-link"
            onClick={(e) => handleLinkClick(e, "services")}
          >
            SERVICES
          </a>
        </li>
        <li className="py-3 md:py-0">
          <a
            href="#clients"
            className="header-link"
            onClick={(e) => handleLinkClick(e, "clients")}
          >
            CLIENTS
          </a>
        </li>
        <li className="py-3 md:py-0">
          <a
            href="#contact"
            className="header-link"
            onClick={(e) => handleLinkClick(e, "contact")}
          >
            CONTACT
          </a>
        </li>
        <li className="py-3 md:py-0">
          <Link to="/about" className="header-link" onClick={closeMenu}>
            ABOUT US
          </Link>
        </li>
      </>
    );
  } else {
    // Default behavior for other routes.
    menuItems = (
      <>
        <li className="py-3 md:py-0">
          <Link
            to="/"
            className="header-link"
            onClick={() => {
              closeMenu();
              window.scrollTo(0, 0);
            }}
          >
            HOME
          </Link>
        </li>
        <li className="py-3 md:py-0">
          <a
            href="#services"
            className="header-link"
            onClick={(e) => handleLinkClick(e, "services")}
          >
            SERVICES
          </a>
        </li>
        <li className="py-3 md:py-0">
          <a
            href="#clients"
            className="header-link"
            onClick={(e) => handleLinkClick(e, "clients")}
          >
            CLIENTS
          </a>
        </li>
        <li className="py-3 md:py-0">
          <a
            href="#contact"
            className="header-link"
            onClick={(e) => handleLinkClick(e, "contact")}
          >
            CONTACT
          </a>
        </li>
        <li className="py-3 md:py-0">
          <Link to="/about" className="header-link" onClick={closeMenu}>
            ABOUT US
          </Link>
        </li>
      </>
    );
  }

  return (
    <header
      className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${
        scrolling ? "bg-white bg-opacity-60 shadow-md" : "bg-white"
      }`}
    >
      <div className="container mx-auto px-4 md:px-8 py-6 md:py-8 flex flex-col md:flex-row justify-between items-center">
        <img src={nwLogo} alt="NW Logo" className="w-72 md:w-80 h-auto" />
        <button
          onClick={toggleMenu}
          className="menu-toggle-button md:hidden text-cyan-900 focus:outline-none mt-4 md:mt-0"
          aria-label="Toggle menu"
          aria-expanded={menuOpen}
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
        <nav
          ref={menuRef}
          className={`w-full md:w-auto ${
            menuOpen
              ? "bg-transparent border-t-2 border-cyan-900 shadow-lg mt-2"
              : "md:bg-transparent md:border-none md:shadow-none"
          } transition-all duration-300 ${scrolling ? "bg-opacity-70" : ""}`}
        >
          <ul
            className={`md:flex md:space-x-10 text-center flex-col md:flex-row ${
              menuOpen ? "flex" : "hidden"
            } md:flex text-cyan-900 font-clarika-pro-geo-md text-lg md:text-xl ${
              menuOpen ? "responsive-menu" : ""
            }`}
          >
            {menuItems}
          </ul>
        </nav>
      </div>
      <ColorTransitionLine />
    </header>
  );
};

export default Header;
