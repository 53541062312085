import React from "react";

const Slogan: React.FC = () => {
  return (
    <section id="about" className="bg-gray-100 py-10 md:py-20">
      <div className="container mx-auto px-4 md:px-6">
        <h1 className="text-3xl md:text-4xl font-bold text-center text-blue-600 mb-4">
          EXCELLENCE IN CLEANING, MAINTENANCE AND CONSTRUCTION
        </h1>
        <p className="text-base md:text-lg lg:text-xl leading-relaxed text-justify text-cyan-900 font-clarika-pro-geo-lt">
          Our team is comprised of experts who bring decades of hands on
          experience and a passion for delivering superior results. Whether
          you’re looking to maintain a pristine environment, address maintenance
          needs, or undertake a construction project, Nationwide Building
          Solutions is here to deliver exceptional services.
        </p>
      </div>
    </section>
  );
};

export default Slogan;
