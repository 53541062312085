import React from "react";

const ColorTransitionLine: React.FC = () => {
  return (
    <>
      <style>
        {`
          @keyframes colorChange {
            0% { background-color: #2f5194; }
            50% { background-color: #e22328; }
            100% { background-color: #2f5194; }
          }
        `}
      </style>
      <div
        className="w-full h-1 mt-4 sm:mt-0"
        style={{
          animation: "colorChange 5s infinite",
          position: "relative",
          zIndex: 0,
        }}
      />
    </>
  );
};

export default ColorTransitionLine;
